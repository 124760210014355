
.bottom-nav{
    position: absolute;
    width:100%;
    bottom:0;
    z-index: 1200;
    margin-bottom:1.95rem;
}

.bottom-nav .nav-button{
    width:calc(50% - 3px);
}

.bottom-nav .spacer{
    width:3px;
    display: inline-block;
    flex: 1 0 auto;
}

.bottom-nav .nav-button:disabled {
    color: #777574;
    background-color: #494746;
}

.bottom-nav img{
    width:25px;
    height:25px;
}

.MuiButton-startIcon{
    margin-right:4px !important;
}