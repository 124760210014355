
.mag-icon {
    position: absolute;
    width: 7vw;
    bottom: 0;
    right: 0;
    margin: 4vw;
    pointer-events: none;
    filter: drop-shadow(0px 0px 5px #22222299);
  }
  
  /*image carousel*/
  
  
  body {
    font-family: Verdana, sans-serif;
    margin: 0;
  }
  .mySlides {
    display: none;
    max-width:100%;
  }
  img {
    vertical-align: middle;
    pointer-events: all;
  }
  
  /* Slideshow container */
  .slideshow-container {
    height: 40%;
    position: relative;
    margin-bottom: 0;
    flex: 0 1 auto;
    text-align: center;

  }

  .slideshow-container img{
    box-sizing: border-box;
  }
  
  /* Next & previous buttons */
  .prev,
  .next {
    /* cursor: pointer; */
    position: absolute;
    top: 50%;
    width: 50px;
    padding: 16px;
    margin-top: -30px;
    filter: drop-shadow(0px 0px 5px #22222277);
  }

  .prev{
    left:0;
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0;
    transform: scaleX(-1);
  }
  
  .prev:active,
  .next:active {
    background: none;
    filter: invert(47%) sepia(70%) saturate(5316%) hue-rotate(343deg)
      brightness(91%) contrast(84%);
  }
  
  #dot-container {
    text-align: center;
    margin-top: 0.5vh;
    flex: 0 1 auto;
  }
  
  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 2.5vw;
    width: 2.5vw;
    margin:5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.5s ease;
  }
  
  .active,
  .dot:active {
    background-color: #d74937;
  }
  
  /* Fading animation */
  .fade {
    animation-name: fade;
    animation-duration: 0.25s;
  }
  
  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
  
  .mySlides {
   height:100%;
   text-align: center;
   position: relative;
  }
  
  .mySlides .img {
    width: auto;
    height: 100%;
    /* pointer-events: none; */
    object-fit: contain;
  }