@font-face {
  font-family: Ringside;
  font-weight: bold;
  src: url(assets/fonts/RingsideRegular-Bold.otf);
}

@font-face {
  font-family: Ringside;
  font-weight: 300; /*book*/
  src: url(assets/fonts/RingsideRegular-Book.otf);
}

@font-face {
  font-family: Ringside;
  font-weight: lighter;
  src: url(assets/fonts/RingsideRegular-Light.otf);
}


#logo-container {
  padding:20px 20px 20px 20px;
  display: inline-block;
  flex: 0 1 auto;
  z-index: 1000;
  width:100%;
  position: absolute;
}

#logo{
  width:80%;
  max-width: 500px;
}

#logo.dark {
  /* color: #d74937*/
  /* filter: invert(42%) sepia(72%) saturate(630%) hue-rotate(322deg) brightness(85%) contrast(100%); */
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(234deg)
    brightness(106%) contrast(106%);

}

#logo .light {
  filter: unset drop-shadow(0px 0px 80px #000000FF) drop-shadow(0px 0px 80px #000000FF)
}

#logo-subtitle {
  display: block;
  font-family: Ringside, sans-serif;
  font-weight: lighter;
  color: black;
  letter-spacing: -0.025em;
  font-size: 0.95em;
  text-transform: uppercase;
}

#logo-subtitle.light{
  color:white !important;
  filter: drop-shadow(0px 0px 80px #222222ff) !important;
}

#logo-subtitle.dark{
  color:black !important;
}

.page-wrapper{
  height:100%;
  text-align:left;
  overflow-y: scroll;
}

.blockout-style{
  
  display:inline;
  padding:0.055em 0.222em 0.148em;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 1.2;

  background-color: black;
  color:white;
  text-align:left;
  font-size: 1.8em;
  letter-spacing: 0.06em;
  word-spacing: -0.05em;
  margin-bottom:-1px;
  font-family: Ringside;
  font-weight:bold;
}


.paragraph-style{
  margin-top:20px;
  margin-bottom: 0px;
  font-family: Ringside;
  font-weight: 300;
  font-size:1em;
  
  letter-spacing: -0.025em;
  word-spacing: -0.03em;
  line-height: 1.35em;
  color:black;
}

.gradient-overlay{
  position: fixed;
  width:100%;
  height:101%;
  display: inline-block;
  background: linear-gradient(
    0deg,
    rgba(241, 240, 236, 1) 0%, 
    rgba(241, 240, 236, 0) 4%, 
    rgba(241, 240, 236, 0) 91%,
    rgba(241, 240, 236, 1) 93%
  );
  pointer-events: none;
  top:0;
}

.loaderOverlay{
  position: absolute;
  height:100%;
  width:100%;
  background-color: #000000DD;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left:0;
}

.instruction-style{
  margin-top:5px;
  margin-bottom: 0px;
  font-family: Ringside;
  font-weight: bold;
  font-size:.95em;
  
  letter-spacing: -0.025em;
  word-spacing: -0.03em;
  line-height: 1.35em;
  color:black;
}

.desktop-feedback{
  background-color: #F1F0EC;
  position: absolute;
  z-index: 9999;
  width:100%;
  height:100%;
  left:0;
  top:0;
  text-align: center;
  display: table-cell;
}

.desktop-feedback-text{
  position: absolute;
  left:50%;
  top:50%;
  margin:1rem;
  font-size: 1.5rem;
  transform: translate(-50%, -50%);
}


/* The rest of this file is auto-imported styling */

.App {
  height:100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #F1F0EC;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*From stylesblitz.com/edit/react-router-template-rpxcvw/*/
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #F1F0EC;
}

html,
body,
div#root {
  padding: 0;
  margin: 0;
  height:100%;
  width:100%;
  box-sizing: border-box;
}

h1 code,
h2 code,
h3 code,
h4 code,
h5 code,
h6 code,
p code,
input code,
button code {
  color: #ff1e1f
}

html,
body,
div#root,
.app-frame, 
.app-main{
  overflow: hidden;
  position:relative;
}

.app-frame{
  display: flex;
  flex-flow: column;
  height: 100%;
}

.app-main{
  flex: 1 1 auto;
  padding-top: 30px;
  overflow-y: scroll;
}

pre {
  color: #00ff8c;
  background-color: #272727;
  border-radius: 4px;
  padding: 12px;
  margin: 12px;
  line-height: 1.2rem;
}

pre,
code {
  font-family: 'Consolas', 'Courier New', Courier, monospace
}
/* 
a {
  text-decoration: none;
  color: #009cff;
}

a:visited {
  text-decoration: none;
  color: #4c00ff;
}

a:hover {
  text-decoration: underline;
}

a code {
  color: #4c00ff;
}

a:visited code {
  color: #009cff;  
} */

.sidebar, .app-body {
  height: 100%;
  overflow: auto;
}

.sidebar {
  width: 10rem;
  min-width: 10rem;
  max-width: 10rem;
  background-color: #eee;
}

.sidebar a {
  margin: 8px;
}

.header {
  background-color: #00000000;/*alpha=0*/
}

.header a {
  margin: 8px;
  color: #000;
}

.header .title{
  font-size: 0.8rem;
  font-weight: 700;
}

.header .subtitle{
  font-style: normal;
  font-size: 0.65rem;
}

.container {
  padding: 4px;
}

.card {
  margin: 4px;
  padding: 4px;
  background-color: #ffffff;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;  
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex {
  flex: 1 1 auto;
}

.wrap {
  flex-wrap: wrap;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-baseline {
  justify-content: baseline;
}

.align-flex-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-flex-end {
  align-items: flex-end;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

.buttonBar{
  position: absolute;
  bottom:1.95rem;
  left:0;
  right:0;
}

.scroll-wrapper{
  overflow-y: scroll;
  width:100%;
  flex:1 1 auto;
  position: relative;
}

button{
  font-size: 12px !important;
  border-radius: 0 !important;
  text-transform: none !important;
  padding: 8px 5px !important;
}

button:disabled {
  color: #777574;
  background-color: #494746;
}


.hidden{
  display: none;
}

iframe{
  width: 100vw;
  height: 100%;
  position: absolute;

  top:0;
  left:0;
  padding:0;
  margin:0;
  border: none;
}

.AR-Container{
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
}
