.home-container{
    width:100%;
    height:100%;
}

.links{
    position: absolute;
    bottom:0;
    width:100vw;
}

.bottom-nav-link{
    flex: auto;
    position: relative;
    display: inline-block;
    z-index: 1300;
    background-color: #3a3a3a;
    color:white;
    text-align: center;
    font-family: Ringside;
    font-weight: lighter;
    font-size: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

    /* one item */
    .bottom-nav-link:first-child:nth-last-child(1) {
    /* -or- li:only-child { */
        width: 100%;

    }
    
    /* two items */
    .bottom-nav-link:first-child:nth-last-child(2),
    .bottom-nav-link:first-child:nth-last-child(2) ~ .bottom-nav-link {
        width: 50%;
    }
    
    /* three items */
    .bottom-nav-link:first-child:nth-last-child(3),
    .bottom-nav-link:first-child:nth-last-child(3) ~ .bottom-nav-link{
        width: 33.3333%;
    }
    
    /* four items */
    .bottom-nav-link:first-child:nth-last-child(4),
    .bottom-nav-link:first-child:nth-last-child(4) ~ .bottom-nav-link {
        width: 25%;
    }
