.mapContainer{
    position: absolute;
    width:100%;
    height: 100%;
    background:#000000;
    top:0;
}

.relativeMapContainer{
  position: absolute;
  width:100%;
  vertical-align:middle;
  top:50%;
  transform: translateY(-50%);
}

.hidden{
  visibility: hidden;
}


.map{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
}

.map-hotspot{
  position: absolute;
  width:40px;
  height:40px;
  margin-left:-10px;
  margin-top:-20px;
  pointer-events:all;
}


.map-list{
  position: relative;
  color:white;
  font-family: Ringside;
  font-size: 1.55vh;
  text-align: center;
  letter-spacing: -0.025em;
  font-weight: normal; 
  text-align: left; 
  column-count: 2;
  width:100%;
}
/* 
.map-instructions{
  color:white;
  font-family: Ringside;
  font-size: 1.55vh;
  text-align: center;
  letter-spacing: -0.025em;
  font-weight: normal; 

  pointer-events: none;
  position:absolute;
  text-align: left;
  column-count: 2;
  bottom:25%;
  left:0;
  transition: opacity 0.5s;
  box-sizing: border-box;
  padding-left:16px;
  width:100%;
} */

.map-list li{
  margin-right: 2vw;
  margin-bottom:4px;
}

.map-list ::marker{
  color: #FFFFFF88;
}


.pulse{
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); } 
} 

.map-hotspot .hit
{
  background-color: #FF000000;
  width:40px;
  height:40px;
  top:0px;
  left:0px;
  position: absolute;
}

.map-hotspot img{
  width:100%;
  height:100%;
}

.map-hotspot-number{
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);

  color:white;
  font-family: Ringside;
  font-size: 10px;
  text-align: center;
  letter-spacing: -0.025em;
  font-weight: normal; 
}

.buttonBar{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.hotspot-container{
  position: absolute;
  width:100%;
  height:100%;
  object-fit: contain;
}

.map .react-transform-component{
  top:-14vh;
}


.react-transform-wrapper {
  display: flex;
  height: 100% !important;
  width: 100% !important;
}

.map-image {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  flex-direction: column;
  display: flex;
  justify-content: center;
  }

.map-image img{
  object-fit: contain;
  width: 100%;
}

.you-are-here{
  pointer-events: none;
    position: absolute;
}