.tour-button-wrapper{
    width:100%;
    margin-bottom:-5px;
    /*height:22vh;*/
    position: relative;
}

.tour-button-image{
    width:100%;
    padding:10px 15px 15px 15px;
    object-fit: cover;
    box-sizing: border-box;
}

.blockout-title{
    background-color: #d74937;
    font-weight: bold;
    font-size:1.04em;
    letter-spacing: -0.025em;
    word-spacing: -0.03em;
    line-height: 1.35em;
    position: relative;
    min-width:120px;
    display: inline-block;

    left:0.5vw;
    bottom:25px;
    padding:1.7% 4% 3.2% 4%;
}

.blockout-subtitle{
    font-weight: bold;
    font-size:.6em;
    letter-spacing: -0.025em;
    word-spacing: -0.03em;
    line-height: 1.35em;
    
    position:absolute;
    bottom:-15px;
    right:-10px;
    padding:2.2% 4%;
}