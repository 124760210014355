
.global-button{
    color:white;
    font-family: Ringside;
    font-size: 0.85em !important;
    letter-spacing: -0.025em;
    font-weight: bold; 
}


.global-button:disabled {
    color: #777574;
    background-color: #494746;
}

.global-button .icon{
    height:3vh;
    width:auto;
}