
#container-2d {
    background: #F1F0EC;
    width: 100%;
    height:100%;
    padding-top:80px;
    background-color: #F1F0EC;
    text-align: left;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 900;
  }

  .previous-button{
    position: absolute !important;
    bottom: 1.95rem;
    left:0;
    width:calc(35%);

    font-family: Ringside, sans-serif !important;
    font-weight: 300 !important;
    letter-spacing: -0.025em !important;
    line-height:1 !important;
    font-size: 0.75em !important;
    color: #d74937 !important;
    background-color: #F1F0EC !important;
    border-style: none !important;
    border-width: 0 !important;
    outline-width: 0 !important;
    box-shadow: none !important;

    padding:8px !important;

  }

  .next-button{
    position: absolute !important;
    bottom: 1.95rem;
    right:0;
    width:calc(35%);
    
    font-family: Ringside, sans-serif !important;
    font-weight: 300 !important;
    letter-spacing: -0.025em !important;
    line-height:1 !important;
    font-size: 0.75em !important;
    color: #d74937 !important;
    background-color: #F1F0EC !important;
    border-style: none !important;
    border-width: 0 !important;
    outline-width: 0 !important;
    box-shadow: none !important;
    padding:8px !important;

  }

.spacer{
    width:3px;
    display: inline-block;
    flex:0 0 auto;
}

#global-nav{
  
  pointer-events: none;
  width:100%;
  
  transition: opacity 0.5s;
  opacity: 0;
}


.button{
  width:calc(33%);
}

  
#text-container {
    overflow-y: auto;
    flex: 1 1 auto;
    padding:0px 20px;
  }

  #text-container a{
    padding-top:12px;
    font-family: Ringside, sans-serif;
    font-weight: 300;
    letter-spacing: -0.025em;
    font-size: 1.059em;
    display: block;
  }

  #text-container a:link, 
  #text-container a:visited, 
  #text-container a:hover, 
  #text-container a:active{
    color:#d74937;
  }
  
  .title-text {
    font-family: Ringside, sans-serif;
    font-weight: bold;
    color: black;
    letter-spacing: -0.025em;
    font-size: 1.58em;
    display: block;
  }
  
  .body-text {
    font-family: Ringside, sans-serif;
    font-weight: 300;
    color: black;
    letter-spacing: -0.025em;
    font-size: 1.059em;
    display: block;
    /* padding-bottom:100px !important; */
  }
  
  #learn-more-container {
    text-align: center;
    flex: 1 1 auto;
  }
  
  #learn-more-instruction-text {
    display: block;
    font-family: Ringside, sans-serif;
    font-weight: bold;
    color: black;
    letter-spacing: -0.025em;
    font-size: 1.08em;
  }
  
  #learn-more-button {
    border-style: solid;
    border-width: 0.6vw;
    border-color: #d74937;
    background-color: white;
    width: 54%;
    padding: 0.6em;
    margin: 1vh;
    color: #d74937;
    font-family: Ringside, sans-serif;
    font-weight: bold;
    letter-spacing: -0.025em;
    font-size: 1.08em;
  }
  
  #learn-more-button:active {
    background-color: #d74937;
    color: white;
  }

  
#close-button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex:0 1 auto;
    margin-bottom: 1.95rem;

    padding-top:10px;
    background: linear-gradient(
      0deg,
      rgba(241, 240, 236, 1) 0%,
      rgba(241, 240, 236, 0.9) 35%,
      rgba(241, 240, 236, 0) 100%
    );
  }
  
  
  form{
    width:calc(33%);
  }
  
  #close-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(33%);
    height: 5.5vh;
    font-family: Ringside, sans-serif;
    font-weight: bold;
    letter-spacing: -0.025em;
    font-size: 0.9em;
    color: white;
    background-color: #d74937;
    border-style: none;
    border-width: 0;
    outline-width: 0;
  }
  
  #close-button:active {
    color: #d74937;
    background-color: white;
    border-style: solid;
    border-width: 0.6vw;
    border-color: #d74937;
  }

  .video-container{
    width: 100%;
    height:auto;
  }