.intro-image{
    width: 100%;
    flex: 0 1 auto;
    margin-bottom:2vh;
    box-sizing: border-box;
    margin: 20px 0px;
    object-fit: contain;
}

.qr-code{
    width: 40%;
    display: block;
    float: right;
    margin-left:20px;
    margin-bottom:20px;
}

.intro-wrapper{
    width:100vw;
    padding: 80px 20px 0px 20px;
    box-sizing: border-box;
    background: #F1F0EC;
}

.intro-scroll-container{

    overflow-y: overlay;
}

.intro-spacer{
    display: block;
    width: 100%;
    height:51px;
}


.paragraph-style{
    margin-top: 0px;
}

.intro-wrapper::-webkit-scrollbar, 
.paragraph-style::-webkit-scrollbar { 
    width:3px;
}

.intro-wrapper::-webkit-scrollbar-track, .paragraph-style::-webkit-scrollbar-track {
    /* -webkit-box-shadow: unset; 
    -webkit-border-radius: unset;
    border-radius: unset;
    background:unset;     */
    background:transparent;
}

.intro-wrapper::-webkit-scrollbar-thumb, 
.paragraph-style::-webkit-scrollbar-thumb { 
   
    /* -webkit-border-radius:unset;
    border-radius:unset;
    -webkit-box-shadow:unset;   */
    background:rgba(0,0,0,0.3);
    
}

