.tour-menu {
    width:100%;
    height:100%;
    padding: 5px 20px 0px 20px;
    box-sizing: border-box;
    background: #F1F0EC;
    flex: 1 1 auto;
    position: absolute;
    top:0;
    padding-top:80px;
    z-index: 100;
}

.tour-menu .bottom-nav{
    display:flex;
    left:0;
    justify-content: center;
    padding-top:40px;
    background: linear-gradient(
        #F1F0EC00 0%,
        #F1F0ECFF 100%
    )

}