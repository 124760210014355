
#pinch-zoom-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    background-color: #000000dd;
    transition: opacity 0.5s;
    overflow: hidden;
    z-index: 10000;
    text-align: left;
    pointer-events: all;
  }
  
  #pinch-zoom-text-container {
    position:absolute;
    top: 64.5vh; 
    padding: 3vh;
    height: 12.5vh;
    overflow-y:hidden;
    z-index: 0;
    
  }
  
  #pinch-zoom-title-text {
    color: white;
  }
  
  #pinch-zoom-body-text {
    color: white;
    letter-spacing: 0.07em;
    word-spacing: -0.12em;
    line-height: 1.4em;
    font-size:0.9em;
  }
  
  #pinch-zoom-cred-text {
    font-family: Ringside, sans-serif;
    font-weight: 300;
    font-style: italic;
    color: white;
    letter-spacing: -0.025em;
    font-size: 1.059em;
    margin: 1.5vh 8.5vw -0.5vh 5.5vw;
    display: block;
    line-height: 2.55vh;
  }

  
  #pinch-zoom-image {
    /* width: 100vw; */
    max-width: 100vw;
    max-height: 47vh;
    margin: 0;
    object-fit: contain;
    object-position: bottom;
  }

  .react-transform-component{
   /* align-self: center;
     */
     position: absolute;
    top: -7vh;
  }
  
  #pinch-zoom-hand {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s;
    width: 23vw;
    height: auto;
  }
  
  .close-button{
    position:absolute !important;
    bottom:1.95rem !important;
    left:50% !important;
    transform: translateX(-50%) !important;
    padding: 1vh 12vw 1vh 12vw !important;
  }