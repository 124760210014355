.scan-container{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    transition: opacity 0.5s;
    text-align: center;
}

.scan-container.black{
    background-color: #000000CC;
}

.scan-center svg{
   display: inline-block;
}

.scan-instructions{
    color:white;
    font-family: Ringside;
    font-size: 2.0vh;
    text-align: center;
    letter-spacing: -0.025em;
    font-weight: 600; /*book*/
    padding-left:16px;
    padding-right:16px;
}

.nav-button-alt{
    position: absolute !important;
    width:42.5%;
    bottom:23vh;
    z-index: 100;
    pointer-events: all;
}

.scan-center .nav-button{
   width:30%;
   margin-top:50px;
}

.scan-center{
    width:100%;
}

.scan-instructions.scanning{
    position: absolute;
    left:50%;
    transform:translateX(-50%);
    /* margin-bottom: 110%; */
    bottom:18vh;
    width:75%;
}

.arrow-container{
    position:absolute;
    left:50%;
    transform: translateX(-50%);
    bottom:7rem;
}

.scan-marker-image-overlay{
    
    object-fit: contain;
    opacity: 0.5;
}

.scan-marker-image{
    width:78vw;
    height:78vw;
    object-fit: contain;
    margin:1.5vh
}

.scan-overlay{
    pointer-events: none;
    position:absolute;
    top:0;
    left:0;
    transition: opacity 0.5s;
    width:100%;
    height:100%;
    align-items: center;

    display: flex;
  flex-direction: column;
  justify-content: center;
}

.scan-frame{
    display: block;
    width:85vw;
    height:85vw;
    margin:1.5vh
}